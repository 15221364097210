import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import VueContentPlaceholders from 'vue-content-placeholders'
import Vuelidate from 'vuelidate'
import VModal from 'vue-js-modal'
import VueGeolocation from 'vue-browser-geolocation';

Vue.use(VueContentPlaceholders)
Vue.use(Vuelidate)
Vue.use(VModal, { dialog: true })
Vue.use(VueGeolocation);

Vue.config.productionTip = false;

new Vue({
  router,
  render: h => h(App),
  el: "#app", 
});
