import Vue from "vue";
import Router from "vue-router"; 
const Master = () => import('./layouts/Master.vue')
const MasterNavbar = () => import('./layouts/MasterNavbar.vue')
const Absensi = () => import('./views/absensi/Index.vue')
const Paket = () => import('./views/paket/Index.vue')
const KendaraanDinas = () => import('./views/kendaraan/Index.vue')
const AddKendaraanDinas = () => import('./views/kendaraan/Add.vue')
const CekLokasi = () => import('./views/cek-lokasi/Index.vue')
const AddCekLokasi = () => import('./views/cek-lokasi/Add.vue')
const CekLokasiOld = () => import('./views/cek-lokasi-old/Index.vue')
const Cuti = () => import('./views/cuti/Index.vue')
const AddCuti = () => import('./views/cuti/Add.vue')
const Lembur = () => import('./views/lembur/Index.vue')
const AddLembur = () => import('./views/lembur/Add.vue')
const Izin = () => import('./views/izin/Index.vue')
const AddIzin = () => import('./views/izin/Add.vue')
const AddCekLokasiOld = () => import('./views/cek-lokasi-old/Add.vue')
const KetidakSesuaian = () => import('./views/ketidak-sesuaian/Index.vue')
const AddKetidakSesuaian = () => import('./views/ketidak-sesuaian/Add.vue')
const Tamu = () => import('./views/tamu/Index.vue')
const AddTamu = () => import('./views/tamu/Add.vue')
const AddPaket = () => import('./views/paket/Add.vue')
const Login = () => import('./views/Login.vue')
const Home = () => import('./views/Home.vue')
const Profil = () => import('./views/profil/Index.vue')


Vue.use(Router);
const routes = [

  // Home
  {
    path: "/", 
    component: Master, 
    meta: {
      authGuard: true,
      title: 'Home Page - Example App',
    },
    children: [
      {
        path: "", 
        component: Home
      }
    ]
  },

  // Login
  {
    path: "/login",
    component: Master, 
    // name: 'login',
    meta: {
      authGuard: false
    },
    children: [
      {
        path: "",
        // name: 'Login',
        component: Login
      }
    ],  
  },

  // Absen
  {
    path: "/absensi",
    component: MasterNavbar,
    meta: {
      authGuard: true
    },
    children: [
      {
        path: "",
        component: Absensi,
        name: "Absensi"
      }
    ]
  },
  // Paket
  {
    path: "/paket",
    component: MasterNavbar,
    meta: {
      authGuard: true
    },
    children: [
      {
        path: "",
        component: Paket,
        name: "Paket"
      },
      {
        path: "add",
        component: AddPaket,
        name: "Tambah Paket"
      }
    ]
  },

  // Kendaraan
  {
    path: "/kendaraan",
    component: MasterNavbar,
    meta: {
      authGuard: true
    },
    children: [
      {
        path: "",
        component: KendaraanDinas,
        name: "Kendaraan Dinas"
      },
      {
        path: "add",
        component: AddKendaraanDinas,
        name: "Tambah Kendaraan Masuk"
      }
    ]
  },
  // Kendaraan Tamu
  {
    path: "/tamu",
    component: MasterNavbar,
    meta: {
      authGuard: true
    },
    children: [
      {
        path: "",
        component: Tamu,
        name: "Tamu"
      },
      {
        path: "add",
        component: AddTamu,
        name: "Tambah Tamu"
      }
    ]
  },
  // Cek Lokasi
  {
    path: "/cek-lokasi",
    component: MasterNavbar,
    meta: {
      authGuard: true
    },
    children: [
      {
        path: "",
        component: CekLokasi,
        name: "Cek Lokasi"
      },
      {
        path: "add",
        component: AddCekLokasi,
        name: "Tambah Lokasi"
      }
    ]
  },
  // Cek Lokasi Old
  {
    path: "/cek-lokasi-old",
    component: MasterNavbar,
    meta: {
      authGuard: true
    },
    children: [
      {
        path: "",
        component: CekLokasiOld,
        name: "Cek Lokasi"
      },
      {
        path: "add",
        component: AddCekLokasiOld,
        name: "Tambah Lokasi"
      }
    ]
  },
  // Cuti
  {
    path: "/cuti",
    component: MasterNavbar,
    meta: {
      authGuard: true
    },
    children: [
      {
        path: "",
        component: Cuti,
        name: "Cuti"
      },
      {
        path: "add",
        component: AddCuti,
        name: "Ajukan Cuti"
      }
    ]
  },
  // izin
  {
    path: "/izin",
    component: MasterNavbar,
    meta: {
      authGuard: true
    },
    children: [
      {
        path: "",
        component: Izin,
        name: "Izin"
      },
      {
        path: "add",
        component: AddIzin,
        name: "Ajukan izin"
      }
    ]
  },
   // izin
  {
    path: "/lembur",
    component: MasterNavbar,
    meta: {
      authGuard: true
    },
    children: [
      {
        path: "",
        component: Lembur,
        name: "Lembur"
      },
      {
        path: "add",
        component: AddLembur,
        name: "Ajukan Lembur"
      }
    ]
  },
  // Ketidak Sesuaian
  {
    path: "/ketidak-sesuaian",
    component: MasterNavbar,
    meta: {
      authGuard: true
    },
    children: [
      {
        path: "",
        component: KetidakSesuaian,
        name: "Ketidaksesuaian"
      },
      {
        path: "add",
        component: AddKetidakSesuaian,
        name: "Tambah Ketidaksesuaian"
      }
    ]
  },
  // Profil
  {
    path: "/profil",
    component: MasterNavbar,
    meta: {
      authGuard: true
    },
    children: [
      {
        path: "",
        component: Profil,
        name: "Profil"
      },
    ]
  },
]

const router = new Router({
  mode: "history",
  routes,
});
 
// Middleware check login
router.beforeEach((to, from, next) => {
  // Setiap Ada Update Harus merubah versi
  let v = '1.08'
  if(v !== localStorage.getItem('vers')){
    // Jika versi berbeda dengan localStorage, makan akan set localstorage baru
    localStorage.setItem('vers', v)
      window.location.reload() // For new version, simply reload on any get
  }

  const loggedInUserDetail = !!localStorage.getItem("token"); 
  if (to.matched.some(m => m.meta.authGuard) && !loggedInUserDetail)
  // cek user sudah autentikasi belum
      next("login");
  // allow page to load
  else next()
});


export default router;