  

<template>
  <div id="app"> 
      <span style="display:block;background-color:red;color:white;text-align:center;position:absolute;width:-webkit-fill-available;z-index:999" v-if="!onLine">Tidak ada koneksi internet</span>
      <span style="display:block;background-color:#4caf50;color:white;text-align:center;position:absolute;width:-webkit-fill-available;z-index:999" v-if="showBackOnline">Terhubung ke internet</span>
      <v-dialog v-if="!onLine"/>  
      <router-view></router-view>
  </div>
</template>

<script> 
export default {
  name: "App",  
  data() {
    return{
      onLine: navigator.onLine,
      showBackOnline: false
    }
  },
  methods: {
    show () {
        // if(!this.onLine){
          this.$modal.show('dialog', {
            title: 'Maaf',
            text: 'No Kerja Atau Password Salah',
            buttons: [
              {
                title: 'Tutup',
                handler: () => {
                  this.$modal.hide('dialog')
                }
              },
            ]
          })  
        // }
        
    },
    updateOnlineStatus(e) {
        const {
            type
        } = e;
        this.onLine = type === 'online';
    }
  },
  watch: {
    onLine(v) {
        if (v) {
            this.showBackOnline = true;
            setTimeout(() => {
                this.showBackOnline = false;
            }, 1000);
        }
    }
    },
    mounted() {
        // console.log('INI MOUNT')
        // this.show()
        window.addEventListener('online', this.updateOnlineStatus);
        window.addEventListener('offline', this.updateOnlineStatus);
    },
    beforeDestroy() {
        window.removeEventListener('online', this.updateOnlineStatus);
        window.removeEventListener('offline', this.updateOnlineStatus);
    },
};
</script>

<style>
@import 'https://fonts.googleapis.com/css?family=Montserrat:400,500,600,700,800&display=swap';
@import 'https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900&display=swap';
@import './assets/css/app.css';
@import './assets/css/font-awesome.css'; 
@import './assets/css/mobile.css';
 
</style>

